import React, {ReactNode, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {Container} from "react-bootstrap";

function Explainer(title: string, contents: ReactNode, plotName: string) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <a href="#" onClick={handleShow}>{title}</a>
            <Modal show={show} onHide={handleClose} className="modal-lg">
                <Container className="container-fluid">
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <img className="img-fluid" src={`https://marcow.io/assets/seagrass/${plotName}.png`} alt="Boxen Plot by Industry"/>
                        </div>
                        <div>
                            {contents}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        Visit the <a href="https://marcow.io/introducing-seagrass-game-comparing-balance-sheets-financial-statements-matching/">tutorial blog post here</a>.
                    </Modal.Footer>
                </Container>
            </Modal>
        </div>
    );
}

export function CashExplainer() {
    const contents = <div>
        <p>The "cash and marketable securities" entry on a balance sheet represents the total amount of cash held by a
            company, as well as any short-term, highly liquid investments that can be easily converted into cash.
            Marketable securities may include treasury bills, commercial paper, and other money market instruments.
        </p>
        <p>This entry is typically listed under the "current assets" section of the balance sheet, as it represents
            assets that can be readily used to cover the company's current liabilities. The cash and marketable
            securities
            entry is an important metric for investors, as it provides insight into a company's liquidity and ability to
            cover short-term obligations.
        </p>
        <p>Companies will often hold cash and marketable securities as a buffer against unforeseen expenses or changes
            in the business environment. However, excessive amounts of cash and marketable securities can also indicate
            that
            the company is not investing its resources effectively or efficiently. As a result, investors may scrutinize
            this entry to determine if the company is managing its resources appropriately.
        </p>
        <h6>Thinking about cash</h6>
        <p>
            Why would a company hold lots of cash?
        </p>
        <p>
            Think about three key ideas:
        </p>
        <ul>
            <li>Cash is fuel for acquisitions.</li>
            <li>Cash is an insurance policy.</li>
            <li>A profitable company without high return-on-investment projects will accumulate cash.</li>
        </ul>
    </div>;
    return Explainer("Cash and marketable securities", contents, "cash_and_equivalents")
}

export function AccountsReceivableExplainer() {
    const contents = <div>
        <p>The "accounts receivable" (AR) entry on a balance sheet is an asset account that represents the amount of
            money
            owed to a company by its customers for goods or services that have been delivered but not yet paid for. In
            other words, it represents the amount of credit extended to customers by the company. Accounts receivable
            are typically short-term assets and are expected to be collected within a year.
        </p>
        <p>Accounts receivable are recorded on the balance sheet at their net realizable value, which is the amount of
            money the company expects to collect from customers after taking into account any bad debts or uncollectible
            accounts. The net realizable value is calculated by subtracting an estimate of bad debts from the total
            accounts receivable balance.
        </p>
        <p>Investors and analysts use accounts receivable turnover ratios to assess a company's ability to collect its
            debts in a timely manner. A high accounts receivable turnover ratio suggests that the company is collecting
            its debts quickly, while a low ratio may indicate that the company is having difficulty collecting payments
            from its customers.
        </p>
        <h6>Thinking about AR</h6>
        <p>
            Why would a company have lots of AR?
        </p>
        <p>
            Companies with strong trust relationships with their customers will have relatively larger AR. Often
            companies that sell to other businesses will offer payment terms extending credit to their customers lasting
            30, 60, or even 90 days.
        </p>
        <p>
            When thinking about AR, ask whether the company largely deals with consumers or other companies.
        </p>
    </div>;
    return Explainer("Accounts Receivable", contents, "accounts_receivable")
}

export function InventoriesExplainer() {
    const contents = <div>
        <p>
            The "Inventories" entry on a balance sheet represents the value of goods that a company holds for sale or
            for use in the production of goods for sale. Inventories can include raw materials, work in progress, and
            finished goods.
        </p>
        <p>Raw materials are materials that are purchased by a company and are used to create its products. Work in
            progress represents goods that are in the process of being manufactured but are not yet complete. Finished
            goods are products that have been fully manufactured and are ready for sale.
        </p>
        <p>The value of inventories on the balance sheet is determined using the lower of cost or market method. This
            means that the inventory is valued at either the cost of producing or acquiring the goods, or the market
            value of the goods, whichever is lower.
        </p>
        <p>The value of inventory is important because it represents a company's investment in goods that have not yet
            been sold. If the value of inventory is too high, it may indicate that the company is overproducing and may
            have difficulty selling its goods in the future. On the other hand, if the value of inventory is too low, it
            may indicate that the company is not producing enough goods to meet demand.
        </p>
        <p>Investors may look at the value of inventory on a balance sheet to gauge a company's ability to manage its
            production processes and maintain a healthy level of goods for sale. However, it is important to note that
            changes in the value of inventory can be influenced by a variety of factors, including changes in demand,
            changes in production costs, and changes in market conditions.
        </p>
        <h6>Thinking about inventories</h6>
        <p>
            Why would a company have lots of inventory?
        </p>
        <p>
            Companies that provide products typically require inventory as production inputs. Companies with little to
            no inventory provide strong evidence that those companies are service providers.
        </p>
    </div>;
    return Explainer("Inventories", contents, "net_inventory")
}

export function OtherCurrentAssetsExplainer() {
    const contents = <div>
        <p>
            The "Other current assets" entry on a balance sheet refers to the various assets a company holds that are
            expected to be converted into cash within a year or less, but do not fit into any of the other current asset
            categories.
        </p>
        <p>
            Some common examples of other current assets include prepaid expenses such as insurance, rent, or taxes, as
            well as short-term investments, loans made to employees or suppliers, and deferred taxes.
        </p>
        <p>
            Prepaid expenses refer to payments made in advance for goods or services that a company expects to receive
            in the future. Short-term investments are investments that a company expects to hold for less than a year,
            such as marketable securities or treasury bills. Loans made to employees or suppliers are typically
            short-term and are expected to be repaid within a year. Deferred taxes are taxes that a company expects to
            pay in the future, but which have been deferred or postponed.
        </p>
        <p>
            The "Other current assets" category can vary widely between companies, depending on the nature of their
            business and their accounting practices. However, it is an important category to track, as it provides
            insight into a company's liquidity and ability to meet short-term financial obligations.
        </p>
    </div>;
    return Explainer("Other Current Assets", contents, "other_current_assets")
}

export function PlantAndEquipmentExplainer() {
    const contents = <div>
        <p>
            The "Plant and equipment (net)" entry on a balance sheet is a line item that represents the value of a
            company's tangible assets used to produce goods and services. This category typically includes items such as
            land, buildings, machinery, and equipment.
        </p>
        <p>
            The value listed on the balance sheet for "Plant and equipment (net)" is typically the book value, which
            represents the historical cost of the asset minus any accumulated depreciation. The net value is used
            because depreciation is factored in, which reflects the decline in value of the asset over time.
        </p>
        <p>
            Depreciation is the process by which companies expense the cost of their assets over their useful lives. As
            assets age, they generally lose value due to wear and tear, obsolescence, or other factors, and depreciation
            is used to recognize this decline in value over time.
        </p>
        <p>
            The "Plant and equipment (net)" entry on the balance sheet is an important indicator of a company's ability
            to generate cash flows from its operations. It also provides insights into the company's level of investment
            in its production capacity and its ability to maintain or replace its assets as needed.
        </p>
    </div>;
    return Explainer("Plant and Other Equipment (net)", contents, "net_ppe")
}

export function OtherAssetsExplainer() {
    const contents = <div>
        <p>
            "Other assets" is a general category on a company's balance sheet that includes any asset that doesn't fit
            into the categories of current assets, investments, property, plant and equipment, and intangible assets.
            This category can be used to account for a variety of items, such as prepaid expenses, deferred tax assets,
            and long-term investments in other companies.
        </p>
        <p>
            Some examples of items that may be included in the "Other assets" category on a balance sheet are:
        </p>
        <ul>
            <li>Prepaid expenses: These are expenses that a company has paid in advance but have not yet been used. For
                example, a company may pay rent for the next six months in advance.
            </li>
            <li>Deferred tax assets: These are assets that arise from temporary differences between the tax and
                accounting treatment of certain items. For example, if a company has a tax loss carryforward, it may
                have a deferred tax asset.
            </li>
            <li>Long-term investments in other companies: These are investments that a company plans to hold for more
                than one year. For example, a company may invest in the stock of another company.
            </li>
            <li>Deposits: These are funds that a company has deposited with another party for a specific purpose. For
                example, a company may have deposited funds with a supplier to ensure the supplier's performance.
            </li>
            <li>Other miscellaneous assets: This may include any other assets that do not fit into the other
                categories.
            </li>
        </ul>
        <p>
            It's important to note that the "Other assets" category can vary widely from company to company, depending
            on the nature of the business and the accounting policies of the company. Investors should carefully review
            a company's balance sheet to understand the specific items included in this category and assess their
            relevance to the company's operations and financial position.
        </p>
        <h6>Thinking about other assets</h6>
        <p>
            Why would a company have lots of "other assets?"
        </p>
        <p>
            Accountants use the Principle of Conservatism, which requires that they cannot assign value to intangible
            assets unless they have a precise value to it. For example, a highly valuable brand is hard to place a
            precise value on. However, when a company acquires another company, the difference between the purchase
            price and the acquired company's asset values is recorded within "other assets" as "goodwill."
        </p>
        <p>
            If a company has a large amount of "other assets," it could be an indication that the company is
            acquisitive.
        </p>
    </div>;
    return Explainer("Other Assets", contents, "other_assets")
}


export function NotesPayableExplainer() {
    const contents = <div>
        <p>
            Notes payable is a liability account on a company's balance sheet that represents the amount owed by the
            company to its creditors in the form of promissory notes. These notes are written promises to pay back the
            borrowed amount along with interest within a specified period.
        </p>
        <p>
            Notes payable are usually short-term in nature and have a maturity period of less than one year. They can be
            used to finance a company's working capital needs, such as purchasing inventory or paying short-term
            expenses. Notes payable can also be used to fund long-term projects or capital expenditures.
        </p>
        <p>
            The balance of notes payable on a company's balance sheet provides insight into its short-term debt
            obligations and the company's ability to meet its financial obligations. A higher balance of notes payable
            may indicate that the company has more short-term debt obligations to pay off, which can increase its
            financial risk. However, notes payable can also be a source of low-cost funding for a company, as they often
            have lower interest rates compared to other forms of debt financing.
        </p>
        <h6>Thinking about other notes payable</h6>
        <p>
            Why would a company have lots of "notes payable?"
        </p>
        <p>
            Aa company with many notes payable may be in financial distress, or may be willingly dilinquent in paying
            suppliers.
        </p>
        <p>
            If a company has a large amount of "other assets," it could be an indication that the company is
            acquisitive.
        </p>
    </div>;
    return Explainer("Notes Payable", contents, "short_term_debt")
}

export function AccruedItemsExplainer() {
    const contents = <div>
        <p>
            On a balance sheet, accrued items refer to expenses that a company has incurred but has not yet paid for.
            These expenses can include wages, interest, taxes, or other operating costs. Accrued items are recorded as
            current liabilities on the balance sheet since they are expected to be paid within one year.
        </p>
        <p>
            For example, a company might have accrued wages payable if they have not yet paid their employees for work
            performed during the current accounting period. Similarly, a company might have accrued interest payable if
            they have not yet made the interest payment on a loan that is due.
        </p>
        <p>
            Accrued items are important to track because they represent expenses that a company has already incurred,
            which can impact their cash flow and overall financial health.
        </p>
    </div>;
    return Explainer("Accrued Items", contents, "accrued_expenses")
}

export function OtherCurrentLiabilitiesExplainer() {
    const contents = <div>
        <p>
            On a balance sheet, "other current liabilities" refers to any obligations or debts that a company owes and
            that are expected to be paid within the next 12 months but do not fall under the categories of accounts
            payable or short-term debt.
        </p>
        <p>
            This entry may include items such as accrued expenses, deferred revenue, and customer deposits. Accrued
            expenses are expenses that have been incurred by a company but have not yet been paid. For example, if a
            company has employees who work during the last week of December but do not get paid until January, the wages
            owed to those employees would be recorded as an accrued expense on the balance sheet. Deferred revenue, on
            the other hand, represents payments that a company has received for goods or services that have not yet been
            delivered. Finally, customer deposits are funds that customers have paid in advance for goods or services
            that have not yet been provided.
        </p>
        <p>
            Other current liabilities are an important consideration for investors and analysts when evaluating a
            company's financial health. A high level of these liabilities could indicate that a company is struggling to
            manage its short-term obligations, which may lead to liquidity problems if it is unable to meet its debt
            obligations as they come due. Therefore, investors may look at the trend of this entry over time to assess
            whether the company is managing its short-term liabilities effectively.
        </p>
    </div>;
    return Explainer("Other Current Liabilities", contents, "other_current_liabilities")
}


export function LongTermDebtExplainer() {
    const contents = <div>
        <p>
            Long-term debt is a liability that reflects the borrowing of a company that is due to be repaid over a
            period of more than one year. It is one of the key components of a company's capital structure and is used
            to fund long-term investments or to cover long-term obligations.
        </p>
        <p>
            On a balance sheet, long-term debt is typically categorized as a noncurrent liability, which means that it
            is not expected to be paid off within the next year. Long-term debt can take various forms, including bonds,
            loans, and other forms of debt securities.
        </p>
        <p>
            The amount of long-term debt a company has can impact its creditworthiness and the interest rate it will pay
            to lenders. Generally, companies with more long-term debt are considered riskier and may have to pay higher
            interest rates to attract investors. However, long-term debt can also provide companies with a source of
            stable financing that allows them to invest in future growth opportunities.
        </p>
        <p>
            Investors should pay attention to a company's long-term debt level and its ability to make regular interest
            payments and repay the principal over time. High levels of long-term debt relative to a company's earnings
            or cash flow could indicate that the company is taking on too much financial risk, which could lead to
            problems in the future. It is important to analyze the terms of the long-term debt, including the interest
            rate, maturity date, and any covenants or restrictions on the company's operations.
        </p>
    </div>;
    return Explainer("Long-Term Debt", contents, "long_term_debt")
}


export function OtherLiabilitiesExplainer() {
    const contents = <div>
        <p>
            On a balance sheet, "Other liabilities" refers to any obligations that a company owes but cannot be
            classified under any other balance sheet categories such as current liabilities, long-term liabilities, or
            equity. Other liabilities may include various types of deferred income, sales tax payable, warranty
            obligations, customer deposits, and other long-term payables.
        </p>
        <p>
            Deferred income refers to cash payments that a company receives in advance for goods or services that it has
            not yet delivered. Sales tax payable represents the amount of sales tax that a company has collected from
            customers but not yet paid to the government. Warranty obligations refer to the amount that a company has
            set aside for future expenses related to warranties on its products or services.
        </p>
        <p>
            Customer deposits refer to the cash that a customer pays in advance to a company for a product or service
            that has not yet been delivered. This is often seen in industries such as real estate or construction, where
            a customer pays a deposit in advance to secure a future delivery of a product or service.
        </p>
        <p>
            "Other liabilities" represents a catch-all category for any outstanding obligations that a company
            has that do not fit under any other categories on the balance sheet. It is important for investors to
            examine the breakdown of "Other liabilities" to better understand the nature and magnitude of a company's
            obligations outside of its core operations.
        </p>
    </div>;
    return Explainer("Other Liabilities", contents, "other_liabilities")
}

export function PreferredStockExplainer() {
    const contents = <div>
        <p>
            Preferred stock is a type of equity security that represents ownership in a corporation. It has features of
            both common stock and debt, and it is issued to investors who are willing to invest in a company but do not
            want to take on the risk of common stock ownership.
        </p>
        <p>
            Unlike common stock, preferred stock may or may not carry voting rights, but it does have a higher claim
            on a company's assets and earnings than common stock. This means that if a company goes bankrupt, preferred
            shareholders have a higher priority in getting paid than common shareholders.
        </p>
        <p>
            Preferred stock also has a fixed dividend rate, which means that the company is obligated to pay a certain
            amount to its preferred shareholders before paying any dividends to its common shareholders. The dividend
            rate can be either fixed or adjustable, and the payment is usually made quarterly.
        </p>
        <h6>Thinking about preferred stock</h6>
        <p>
            Why would a company have preferred stock?
        </p>
        <p>
            Preferred stock provides a way for investors to receive a stable dividend income while also having
            a degree of protection against the company's financial distress. A company might issue preferred stock
            if a company is in distress and the downside risk is significantly elevated. In such a circumstance,
            the company may be unable or unwilling to raise debt on agreeable terms. One recourse is to issue
            preferred stock, which provides a combination of downside protection with upside participation.
        </p>
    </div>;
    return Explainer("Preferred Equity", contents, "preferred_equity")
}

export function EquityExplainer() {
    const contents = <div>
        <p>
            Shareholder's equity on a balance sheet is the residual interest of shareholders in the assets of a company
            after deducting liabilities. It represents the portion of a company's assets that is owned by shareholders,
            or the value of a company's assets that can be attributed to shareholders.
        </p>
        <p>
            Shareholder's equity includes several components such as common stock, additional paid-in capital, retained
            earnings, and accumulated other comprehensive income.
        </p>
        <p>
            Common stock represents the initial capital investment made by shareholders when they purchased shares of
            the company. Additional paid-in capital represents the amount of capital raised by the company that exceeds
            the par value of the common stock.
        </p>
        <p>
            Retained earnings are profits that a company has earned and retained for future use, rather than
            distributing them to shareholders in the form of dividends. Accumulated other comprehensive income includes
            gains or losses that are not part of the company's normal operations, such as changes in the value of
            certain investments or fluctuations in foreign currency exchange rates.
        </p>
        <p>
            Shareholder's equity is an important metric for investors to understand how much of a company's assets are
            financed by shareholders, and how much of the company's earnings have been retained for future growth rather
            than distributed to shareholders. It is also an important measure of a company's financial health and
            ability to weather economic downturns.
        </p>
    </div>;
    return Explainer("Shareholders' Equity", contents, "shareholder_equity")
}

export function CurrentRatioExplainer() {
    const contents = <div>
        <h6>
            The formula for current ratio is current assets / current liabilities.
        </h6>
        <p>
            The current ratio is a financial ratio that measures a company's ability to pay off its short-term
            liabilities with its current assets. It is calculated by dividing current assets by current liabilities.
        </p>
        <p>
            Current assets are assets that a company expects to convert to cash or use up within one year or the
            operating cycle, whichever is longer. They include cash and cash equivalents, accounts receivable,
            inventory, and prepaid expenses.
        </p>
        <p>
            Current liabilities are obligations that are due within one year or the operating cycle, whichever is
            longer. They include accounts payable, accrued expenses, and short-term debt.
        </p>
        <p>
            The current ratio provides an indication of a company's liquidity and ability to meet its short-term
            obligations. Generally, a current ratio of 1 or greater is considered healthy, as it suggests that a company
            has sufficient current assets to pay off its current liabilities. A higher current ratio is typically
            preferred, as it provides a greater margin of safety and indicates that a company is better able to handle
            unexpected expenses or a downturn in business.
        </p>
        <p>
            Debt issuers may also look at the current ratio as an indicator of creditworthiness. A company with a high
            current ratio is generally seen as less risky, as it has a greater ability to meet its short-term
            obligations.
        </p>
    </div>;
    return Explainer("Current", contents, "current")
}

export function QuickRatioExplainer() {
    const contents = <div>
        <h6>
            The formula for current ratio is (current assets - inventory) / current liabilities.
        </h6>
        <p>
            The quick ratio, also known as the acid-test ratio, is a liquidity measure that helps determine a company's
            ability to pay off its short-term liabilities with its most liquid assets. The quick ratio is a more
            conservative measure of liquidity than the current ratio because it excludes inventory, which may be harder
            to convert into cash.
        </p>
        <p>
            To calculate the quick ratio, you add up a company's cash and cash equivalents, marketable securities, and
            accounts receivable, and then divide that sum by the company's current liabilities. The formula for the
            quick ratio is as follows:
        </p>
        <p>
            Quick Ratio = (Cash + Marketable Securities + Accounts Receivable) / Current Liabilities
        </p>
        <p>
            In general, a higher quick ratio is seen as more favorable because it indicates that a company has a larger
            buffer of liquid assets to cover its short-term liabilities. A quick ratio of 1:1 is considered adequate,
            although this can vary by industry. For example, a company in a highly volatile industry or one that
            requires a lot of inventory might need a higher quick ratio to be considered financially healthy.
        </p>
        <p>
            Debt providers might look at a company's quick ratio when determining its creditworthiness because it
            provides a measure of a company's ability to meet its financial obligations in the short term. A low quick
            ratio could indicate that a company is not able to cover its short-term liabilities and may struggle to pay
            back its debts.
        </p>
    </div>;
    return Explainer("Quick", contents, "quick")
}

export function NetProfitMarginExplainer() {
    const contents = <div>
        <h6>
            The formula for (net) profit margin is net profit / revenue.
        </h6>
        <p>
            Net profit margin is a profitability ratio that measures how much of each dollar of revenue earned by a
            company is converted into profit. The formula for net profit margin is:
        </p>
        <p>
            The ratio is expressed as a percentage and represents the amount of profit a company earns per dollar of
            revenue. For example, if a company has a net profit margin of 10%, it means that for every dollar of revenue
            generated, the company retains 10 cents in profit.
        </p>
        <p>
            Analysts look at the net profit margin to determine how efficiently a company is operating and how much
            profit it is generating from its revenue. A higher net profit margin indicates that a company is generating
            more profit per dollar of revenue, which is generally viewed positively by investors and analysts.
        </p>
        <p>
            For example, a company with a net profit margin of 20% would be viewed as more efficient and profitable than
            a company with a net profit margin of 5%. However, it's important to note that net profit margins vary by
            industry and company size, so it's not always useful to compare the net profit margins of companies in
            different sectors or of different sizes.
        </p>
        <p>
            A high net profit margin can be a positive sign for a company's financial health, but it should be analyzed
            alongside other financial metrics to gain a comprehensive understanding of a company's profitability and
            financial strength.
        </p>
    </div>;
    return Explainer("Profit Margin", contents, "profit_margin")
}

export function ReturnOnEquityExplainer() {
    const contents = <div>
        <h6>
            The formula for return on equity is net profit / shareholders' equity.
        </h6>
        <p>
            Return on equity (ROE) is a financial metric that measures a company's profitability by calculating how much
            profit it generates relative to the amount of shareholder equity. ROE is a key indicator of a company's
            performance and is often used by investors to evaluate the efficiency with which a company generates profits
            using shareholders' investments.
        </p>
        <p>
            ROE is calculated by dividing a company's net income by its shareholder equity. Net income is the company's
            total earnings, while shareholder equity is the total value of assets minus the total value of liabilities.
            The resulting figure is expressed as a percentage and represents the return on a dollar of investment in the
            company.
        </p>
        <p>
            For example, if a company has net income of $1 million and shareholder equity of $10 million, its ROE would
            be 10% ($1 million ÷ $10 million = 0.10, or 10%). This means that for every dollar invested in the company,
            it generated 10 cents of profit.
        </p>
        <p>
            Analysts typically look at ROE as a measure of a company's ability to generate profits using the resources
            available to it. A high ROE indicates that the company is generating a significant return on its
            investments, which is generally seen as a positive sign. However, it's important to consider other factors
            as well, such as the company's debt levels, capital structure, and industry trends, as these can all impact
            its profitability and overall performance.
        </p>
    </div>;
    return Explainer("Return on Equity", contents, "return_on_equity")
}

export function ReturnOnAssetsExplainer() {
    const contents = <div>
        <h6>
            The formula for return on assets is net profit / total assets.
        </h6>
        <p>
            Return on assets (ROA) is a financial metric used to measure a company's profitability relative to its
            assets. ROA is calculated by dividing a company's net income by its total assets. The ratio is expressed as
            a percentage and indicates how much profit a company generates for each dollar of assets it owns.
        </p>
        <p>
            ROA is useful in assessing a company's ability to generate profits from its assets. By comparing a company's
            ROA with that of its peers or industry average, an analyst can get a sense of how efficient the company is
            in utilizing its assets to generate profits.
        </p>
        <p>
            For example, if a company has an ROA of 10%, it means that it generated $0.10 of profit for every $1 of
            assets it owns. If the company's ROA is higher than that of its competitors, it suggests that the company is
            better at generating profits from its assets.
        </p>
    </div>;
    return Explainer("Return on Assets", contents, "return_on_assets")
}

export function EbitdaMarginExplainer() {
    const contents = <div>
        <h6>
            The formula for EBITDA margin is EBITDA / revenue.
        </h6>
        <p>
            EBITDA margin is a financial metric that measures a company's earnings before interest, taxes, depreciation,
            and amortization (EBITDA) as a percentage of its revenue. The ratio is calculated by dividing EBITDA by
            total revenue.
        </p>
        <p>
            EBITDA margin is a popular profitability measure used by analysts and investors to evaluate a company's
            operational efficiency and financial health. By excluding certain non-operating expenses, such as interest,
            taxes, depreciation, and amortization, EBITDA provides a more accurate representation of a company's cash
            generation capabilities.
        </p>
        <p>
            The higher the EBITDA margin, the more profitable a company is considered to be. This is because a higher
            EBITDA margin indicates that a company is generating more cash from its operations for every dollar of
            revenue it generates.
        </p>
        <p>
            Analysts use the EBITDA margin to compare a company's profitability to its peers in the same industry. This
            helps them determine if the company is operating efficiently relative to its competitors. A high EBITDA
            margin indicates that the company has a competitive advantage over its peers, while a low EBITDA margin
            suggests that the company may be facing operational or financial challenges.
        </p>
        <p>
            Overall, the EBITDA margin is a valuable metric for investors to evaluate a company's profitability and cash
            generation capabilities. It is important to note, however, that the EBITDA margin should not be viewed in
            isolation and should be considered alongside other financial metrics to gain a comprehensive understanding
            of a company's financial health.
        </p>
    </div>;
    return Explainer("EBITDA margin", contents, "ebitda_margin")
}

export function DebtToAssetsExplainer() {
    const contents = <div>
        <h6>
            The formula for debt to assets (DTA) is total debt / total assets.
        </h6>
        <p>
            The debt to assets ratio is a financial ratio used to evaluate the level of leverage of a company. It is
            calculated by dividing a company's total debt by its total assets. The ratio indicates the percentage of the
            company's assets that are financed by debt, and can be expressed as a percentage or a decimal.
        </p>
        <p>
            A high debt to assets ratio indicates that a company has a greater proportion of debt relative to its
            assets. This means that the company is more reliant on debt financing to fund its operations, and has a
            higher risk of defaulting on its debt obligations. On the other hand, a low debt to assets ratio indicates
            that a company has a greater proportion of assets financed by equity, which means that the company is less
            reliant on debt financing and has a lower risk of default.
        </p>
        <p>
            The debt to assets ratio is used by investors and analysts to evaluate the financial risk of a company, as
            well as to compare the financial leverage of different companies within the same industry. However, it is
            important to note that the ideal debt to assets ratio can vary depending on the industry and the business
            model of the company. For example, capital-intensive industries such as construction and utilities may have
            higher debt to assets ratios than service-oriented industries such as software and consulting.
        </p>
    </div>;
    return Explainer("Debt to Assets", contents, "debt_to_assets")
}

export function DebtToCapitalizationExplainer() {
    const contents = <div>
        <h6>
            The formula for debt to capitalization (DTC) is long-term debt / (long-term debt + shareholder's equity).
        </h6>
        <p>
            The debt-to-capitalization ratio, also known as the debt-to-cap ratio, is a financial leverage ratio that
            measures the proportion of a company's debt relative to its total capitalization, which includes both debt
            and equity. It is used to evaluate a company's ability to meet its financial obligations in the event of
            economic downturns, as well as to assess its overall financial health and risk profile.
        </p>
        <p>
            The total debt represents all of the company's outstanding debt obligations, including long-term debt,
            short-term debt, and any other debt that needs to be repaid within a year. The total equity represents the
            value of all of the company's outstanding shares of common and preferred stock, as well as any retained
            earnings.
        </p>
        <p>
            The debt-to-capitalization ratio is expressed as a percentage, and a higher percentage indicates a higher
            degree of financial leverage. For example, if a company has total debt of $500 million and total equity of
            $1.5 billion, the debt-to-capitalization ratio would be:
        </p>
        <p>
            Debt-to-Capitalization Ratio = $500 million / ($500 million + $1.5 billion) = 25%
        </p>
        <p>
            This means that 25% of the company's total capitalization is comprised of debt, while 75% is comprised of
            equity.
        </p>
        <p>
            The debt-to-capitalization ratio can be used by investors and analysts to compare the level of leverage of
            different companies within the same industry, as well as to monitor changes in a company's financial
            leverage over time. It is also commonly used by lenders to evaluate the creditworthiness of a company and to
            determine the terms of loans and other credit facilities. A lower debt-to-capitalization ratio is generally
            considered to be more favorable, as it indicates that the company has a lower level of debt relative to its
            overall capitalization and is therefore less risky from a lender's perspective.
        </p>
    </div>;
    return Explainer("Debt to Capitalization", contents, "debt_to_capitalization")
}

export function AssetsToEquityExplainer() {
    const contents = <div>
        <h6>
            The formula for Assets to Shareholder's Equity is total assets / shareholder's equity.
        </h6>
        <p>
            The assets to equity ratio is a measure of leverage that shows how many dollars in assets a company has per
            dollar of shareholder equity. It is calculated by dividing the total assets of a company by its shareholder
            equity.
        </p>
        <p>
            This ratio is important because it shows the extent to which a company is using debt to finance its
            operations. A higher ratio means that the company has a larger proportion of debt in its capital structure,
            which can magnify returns in good times but also magnify losses in bad times.
        </p>
        <p>
            For example, if a company has $2 million in assets and $1 million in shareholder equity, its assets to
            equity ratio would be 2:1. This means that for every $1 of shareholder equity, the company has $2 in assets.
        </p>
        <p>
            If the company were to use debt to finance some of its operations, its assets would increase, but so would
            its liabilities. This would increase the assets to equity ratio, which would make the company more highly
            leveraged.
        </p>
        <p>
            On the other hand, if the company were to issue new shares of stock to raise capital, its equity would
            increase, which would decrease the assets to equity ratio and make the company less leveraged.
        </p>
        <p>
            Overall, the assets to equity ratio is an important measure of a company's financial health and its ability
            to withstand financial stress. A company with a high assets to equity ratio may be more susceptible to
            financial distress, while a company with a low ratio may be more stable and less risky.
        </p>
    </div>;
    return Explainer("Assets to Equity", contents, "assets_to_equity")
}


export function InterestCoverageRatioExplainer() {
    const contents = <div>
        <h6>
            The formula for Interest Coverage Ratio is EBIT / interest expenses.
        </h6>
        <p>
            The interest coverage ratio is a financial metric that measures a company's ability to meet its interest
            payments on outstanding debt. It is also known as the times interest earned (TIE) ratio. The ratio is
            calculated by dividing a company's earnings before interest and taxes (EBIT) by its interest expense.
        </p>
        <p>
            The interest coverage ratio is an important measure of a company's financial health, as it indicates whether
            the company has enough earnings to cover its interest payments. A high ratio indicates that the company is
            generating enough earnings to easily meet its interest obligations, while a low ratio suggests that the
            company may have difficulty meeting its interest payments.
        </p>
        <p>
            For example, if a company has an EBIT of $1,000,000 and an interest expense of $200,000, its interest
            coverage ratio would be 5 ($1,000,000/$200,000). This means that the company is generating five times more
            earnings than it needs to cover its interest payments.
        </p>
        <p>
            Investors use the interest coverage ratio to assess a company's ability to service its debt obligations. A
            higher interest coverage ratio indicates that the company is more likely to be able to make its interest
            payments, which is generally viewed as a positive sign for investors. A lower interest coverage ratio, on
            the other hand, may indicate that the company is taking on too much debt or is not generating enough
            earnings to cover its interest payments, which could be a warning sign for investors.
        </p>
        <p>
            It is important to note that the interest coverage ratio does not take into account other obligations such
            as taxes, dividends, or capital expenditures. Therefore, it should be used in conjunction with other
            financial metrics when evaluating a company's overall financial health.
        </p>
    </div>;
    return Explainer("Interest Coverage", contents, "interest_coverage")
}


export function AssetTurnoverRatioExplainer() {
    const contents = <div>
        <h6>
            The formula for Asset Turnover is revenue / total assets.
        </h6>
        <p>
            The asset turnover ratio is a financial ratio that measures how effectively a company is using its assets to
            generate revenue. It is calculated by dividing the company's net sales by its total assets.
        </p>
        <p>
            The asset turnover ratio is an important indicator of a company's operational efficiency. A high ratio
            indicates that the company is generating a large amount of revenue relative to its assets, which is
            generally seen as a positive sign. On the other hand, a low ratio may indicate that the company is not using
            its assets efficiently.
        </p>
        <p>
            It is important to note that different industries may have different asset turnover ratios, as the nature of
            the business may require different amounts of assets to generate revenue. For example, a manufacturing
            company may have a lower asset turnover ratio than a consulting firm, as the former requires more fixed
            assets to produce goods.
        </p>
        <p>
            Investors and analysts use the asset turnover ratio to evaluate a company's operational efficiency and
            compare it to other companies in the same industry. By understanding a company's asset turnover ratio,
            investors can gain insight into how effectively the company is using its assets to generate revenue and
            whether it is able to compete with other players in the market.
        </p>
    </div>;
    return Explainer("Asset Turnover", contents, "asset_turnover")
}

export function InventoryTurnoverExplainer() {
    const contents = <div>
        <h6>
            Inventory Turnover measures how many times a company sells all of its inventory on an annual basis.
        </h6>
        <p>
            Inventory turnover is a financial ratio that measures the number of times a company sells and replaces its
            inventory during a given period. It is calculated by dividing the cost of goods sold by the average
            inventory during the same period. A high inventory turnover ratio indicates that a company is selling its
            products quickly and efficiently and is managing its inventory effectively.
        </p>
        <p>
            A high inventory turnover ratio may be a positive signal to investors and lenders, as it suggests that the
            company is effectively managing its inventory, which can lead to cost savings and increased profitability. A
            low inventory turnover ratio may indicate that a company is holding onto inventory for too long, which can
            tie up capital and increase the risk of inventory becoming obsolete or unsellable.
        </p>
        <p>
            It is important to note that inventory turnover ratios can vary widely by industry, so it is important to
            compare a company's ratio to those of its peers in the same industry. Additionally, a high inventory
            turnover ratio can also be indicative of aggressive pricing strategies, which could lead to reduced profit
            margins in the long term.
        </p>
    </div>;
    return Explainer("Inventory Turnover", contents, "inventory_turnover")
}

export function DaysInventoryExplainer() {
    const contents = <div>
        <h6>
            Days inventory is 365 / inventory turnover.
        </h6>
        <p>
            The days inventory ratio is a financial ratio that measures the average number of days a company holds its
            inventory before selling it. It is calculated by dividing the inventory by the cost of goods sold, and then
            multiplying the result by the number of days in the period.
        </p>
        <p>
            A higher days inventory ratio suggests that a company is taking longer to sell its inventory, which could
            indicate poor inventory management or sluggish sales. A lower days inventory ratio indicates that a company
            is selling its inventory quickly, which is generally seen as positive because it frees up cash for other
            purposes.
        </p>
    </div>;
    return Explainer("Days Inventory", contents, "days_inventory")
}

export function ReceivablesCollectionPeriod() {
    const contents = <div>
        <h6>
            Receivables collection period is 365 / (sales / receivables).
        </h6>
        <p>
            The receivables collection period is a financial ratio that measures the number of days it takes for a
            company to collect its accounts receivable. It is also known as the days sales outstanding (DSO) ratio. A
            lower DSO indicates that the company is collecting its accounts receivable more quickly, which means it is
            receiving cash from its sales faster.
        </p>
        <p>
            To calculate the receivables collection period, you divide the total accounts receivable by the average
            daily sales. The resulting figure represents the number of days it takes for a company to collect its
            accounts receivable.
        </p>
        <p>
            For example, if a company has $100,000 in accounts receivable and its average daily sales are $10,000, its
            receivables collection period would be 10 days (i.e., $100,000/$10,000 = 10).
        </p>
        <p>
            Analysts use the receivables collection period to assess a company's efficiency in collecting its accounts
            receivable. A longer collection period can indicate that a company is struggling to collect payments from
            its customers, which could lead to cash flow problems. It can also indicate that the company is offering
            lenient payment terms to its customers, which could be a competitive advantage in some industries.
        </p>
        <p>
            A shorter collection period can indicate that a company is efficient in collecting its accounts receivable,
            which can be a positive sign for investors. However, a very short collection period could also indicate that
            the company is too aggressive in collecting its accounts receivable, which could hurt its relationships with
            customers.
        </p>
    </div>;
    return Explainer("Receivables Collection Period", contents, "receivables_collection_period")
}
