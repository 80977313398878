import React from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Download, RefreshCw, Twitter} from "react-feather";

interface HeaderProps {
    url: string;
    allCorrect: any;
    allSelected: any;
    game_id: string;
    setAssignments: any;
    getAssignments: any;
    getTickers: any;
}

export class Header extends React.Component<HeaderProps, any> {

    render() {
        return <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <Col md={2}>
                <a className={`navbar-brand col-md-3 col-lg-2 me-0 px-3 ${this.props.allCorrect() ? "text-success" : "text-danger"}`}
                   href="/">seagrass 🪸</a> <a href="https://marcow.io/introducing-seagrass-game-comparing-balance-sheets-financial-statements-matching/">(tutorial)</a>
            </Col>
            <Col md={7}></Col>
            <Col md={1}>
                {this.props.allSelected() && !this.props.allCorrect() ?
                    <Button id="submitButton"
                            onClick={() => fetch(`${this.props.url}/game/check?game_id=${this.props.game_id}&proposal=${JSON.stringify(this.props.getAssignments())}`)
                                .then(response => response.json())
                                .then(data => {
                                    this.props.setAssignments(data.proposal_elements_correct);
                                })
                                .catch(error => console.error(error))}
                    >Submit</Button> : <Button disabled>Submit</Button>}
            </Col>
            <Col md={1}>
                <a className="navbar-brand col-md-1 col-lg-1 me-0 px-3 text-center"
                   href={`https://twitter.com/intent/tweet?via=marcow_io&url=https://seagrass.marcow.io/?game_id=${this.props.game_id}&hashtags=seagrass&text=${this.tweetText()}`}
                   target={"_blank"}><Twitter/></a>
            </Col>
        </header>;
    }

    private tweetText() {
        const tickerList = this.props.getTickers().map( (x: string) => '$' + x).join(' ');
        const plaintext = `Can you match ${tickerList} with their latest balance sheets?`;
        return encodeURIComponent(plaintext);
    }
}