import React from "react";
import {
    AccruedItemsExplainer, EquityExplainer,
    LongTermDebtExplainer,
    NotesPayableExplainer,
    OtherCurrentLiabilitiesExplainer, OtherLiabilitiesExplainer, PreferredStockExplainer
} from "./Explainers";

interface LiabilitiesProps {
    tickers: string[],
    data: number[][]
}

export class Liabilities extends React.Component<LiabilitiesProps> {
    liabilities = [
        <NotesPayableExplainer/>,
        <AccruedItemsExplainer/>,
        <OtherCurrentLiabilitiesExplainer/>,
        <LongTermDebtExplainer/>,
        <OtherLiabilitiesExplainer/>,
        <PreferredStockExplainer/>,
        <EquityExplainer/>
    ];

    render() {
        return <tbody>
        <tr className={'table-primary'}>
            <th className={'table-primary'} key='liability-header' >Liabilities</th>
            {this.props.tickers.map((x, _) => (<th scope='col'>{x}</th>))}
        </tr>
        {this.liabilities.map((title, index) => (
            <tr key={`liability-${index}`}>
                <td>{title}</td>
                {this.props.data.map((x, i) => (
                    <td key={i}>{(100.0 * x[index]).toFixed(0)}</td>
                ))}
            </tr>
        ))}
        <tr>
            <td key='liability-total'></td>
            {this.props.data.map((a, b) => (
                <td>100</td>
            ))}
        </tr>
        </tbody>
    }
}