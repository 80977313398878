import React from "react";
import {Modal} from "react-bootstrap";
import {
    AccountsReceivableExplainer,
    CashExplainer,
    InventoriesExplainer, OtherAssetsExplainer,
    OtherCurrentAssetsExplainer, PlantAndEquipmentExplainer
} from "./Explainers";

interface AssetsProps {
    tickers: string[],
    data: number[][];
}

export class Assets extends React.Component<AssetsProps, any> {
    assets = [
        <CashExplainer/>,
        <AccountsReceivableExplainer/>,
        <InventoriesExplainer/>,
        <OtherCurrentAssetsExplainer/>,
        <PlantAndEquipmentExplainer/>,
        <OtherAssetsExplainer/>
    ];

    render() {
        return <tbody>
                <tr key='assets-header' className={'table-primary'} >
                    <th scope='col'>Assets</th>
                    {this.props.tickers.map((x, _) => (<th scope='col'>{x}</th>))}
                </tr>
                {this.assets.map((title, index) => (
                    <tr key={`assets-${index}`}>
                        <td>{title}</td>
                        {this.props.data.map((x, i) => (
                            <td key={i}>{ (100.0 * x[index]).toFixed(0)}</td>
                        ))}
                    </tr>
                ))}
                <tr key='assets-total'>
                    <td></td>
                    {this.props.data.map((a, b) => (
                        <td>100</td>
                    ))}
                </tr>
            </tbody>
    }
}