import React, {useCallback} from 'react';
import './App.css';
import Game from "./components/Game";
import Selection from "./components/Selection"
import type { Container, Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import ReactGA from 'react-ga';

const is_dev = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
const api_url = is_dev ? "/api" : "https://api.seagrass.marcow.io/api";

function App() {
    const queryParameters = new URLSearchParams(window.location.search);
    const game_id = queryParameters.get("game_id");

    const particlesInit = useCallback(async (engine : Engine) => {
        console.log(engine);
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container : Container) => {
        await console.log(container);
    }, []);

    const apiUrl = `${api_url}`;

    ReactGA.initialize('G-8XKF7WRCR9', { debug: true });
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (game_id) {
        return <Game url={apiUrl}
              game_id={`${game_id}`}
              particlesInit={particlesInit}
              particlesLoaded={particlesLoaded}/>;
    }

    return <Selection url={apiUrl}/>
}

export default App;
