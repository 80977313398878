import {Assets} from "./Assets";
import {Calculations} from "./Calculations";
import {Liabilities} from "./Liabilities";
import {Company} from "./Company";
import {Header} from "./Header";
import moment from "moment";

import Particles from "react-particles";
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown"
import Row from "react-bootstrap/Row";
import React from "react";
import {Accordion, OverlayTrigger, Popover} from "react-bootstrap";


interface BalanceSheetProps {
    url: string,
    game_id: string,
    particlesInit: any,
    particlesLoaded: any
}

interface BalanceSheetState {
    assignments: number[],
    correct: boolean[],
    companies: Company[],
    assets: number[][],
    liabilities: number[][],
    calculations: number[][],
    game_id: string
}

export default class Game extends React.Component<BalanceSheetProps, BalanceSheetState> {
    constructor(props: BalanceSheetProps) {
        super(props);
        fetch(`${this.props.url}/game?game_id=${this.props.game_id}`)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    assets: data.assets,
                    liabilities: data.liabilities,
                    calculations: data.calculations,
                    companies: data.companies,
                    assignments: data.companies.map((_: Object) => (-1)),
                    correct: data.companies.map((_: Object) => false),
                    game_id: data.game_id
                })
            }).catch(error => console.error(error));
        this.state = {
            assignments: [],
            companies: [],
            assets: [],
            liabilities: [],
            calculations: [],
            correct: [],
            game_id: ''
        }
    }

    handleSelection(company_index: number, selection_index: number) {
        console.log('handle selection');
        let newAssignments = this.state.assignments;
        for (let i = 0; i < newAssignments.length; i++) {
            if (newAssignments[i] === company_index)
                newAssignments[i] = -1;
        }
        newAssignments[selection_index] = company_index;
        this.setState({
            assignments: newAssignments
        })
    }

    findAssignmentFor(company_index: number) {
        for (let i = 0; i < this.state.assignments.length; i++) {
            if (this.state.assignments[i] === company_index)
                return i;
        }
        return -1;
    }

    isSolved() {
        return this.state.correct.length > 0 && this.state.correct.every(x => x);
    }

    render_assignment_for(index: number) {
        const title = this.findAssignmentFor(index) >= 0 ? `#${this.findAssignmentFor(index) + 1}` : "Unassigned";

        return this.isSolved() ? <Button variant="success">{title}</Button> :
            <Dropdown>
                <Dropdown.Toggle variant={this.state.correct[index] ? "success" : "secondary"} id="dropdown-basic"
                                 size="sm">{title}</Dropdown.Toggle>
                <Dropdown.Menu>
                    {this.state.assignments.map((i, j) => (
                        <Dropdown.Item
                            onClick={() => this.handleSelection(index, j)}>{i >= 0 ? this.state.companies[i].ticker : `#${j + 1}`}</Dropdown.Item>
                    ))}
                    <Dropdown.Divider/>
                    <Dropdown.Item onClick={() => this.handleSelection(index, -1)}>Unassign</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>;
    }

    render_sidebar() {
        return <nav id="sidebarMenu" className="col-md-4 d-md-block bg-light sidebar-sticky">
            <Accordion className="position-sticky" id="company_accordion" defaultActiveKey="0">
                {this.state.companies.map((c, i) => (
                    <Accordion.Item eventKey={`"${i}"`}>
                        <Accordion.Header id={`heading_${i}`}>
                            <h6>{c.name} (${c.ticker})</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td>Assign</td><td>{this.render_assignment_for(i)}</td>
                                    </tr>
                                    <tr>
                                        <td>Sector</td><td>{c.industry_sector}</td>
                                    </tr>
                                    <tr>
                                        <td>Group</td><td>{c.industry_group}</td>
                                    </tr>
                                    <tr>
                                        <td>Category</td><td>{c.industry_category}</td>
                                    </tr>
                                    <tr>
                                        <td>Market Cap</td><td>${(c.market_cap / 1000000000).toFixed(2)}B</td>
                                    </tr>
                                    <tr>
                                        <td>Employees</td><td>{c.employees}</td>
                                    </tr>
                                    <tr>
                                        <td>Dividend Yield</td><td>{(c.dividend_yield * 100).toFixed(1)}%</td>
                                    </tr>
                                    <tr>
                                        <td>Reporting Date</td><td>{moment(c.date, moment.ISO_8601, true).toDate().toLocaleDateString()}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className={'list-group-item'}>

                                <Row>
                                    {c.short_description}
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            <div>
                <Particles
                id="confetti"
                init={this.props.particlesInit}
                loaded={this.props.particlesLoaded}
                options={{
                    "fullScreen": {
                        "zIndex": 1
                    },
                    "particles": {
                        "color": {
                            "value": [
                                "#FFFFFF",
                                "#FFd700"
                            ]
                        },
                        "shape": {
                            "type": [
                                "circle",
                                "square"
                            ],
                            "options": {}
                        },
                        "move": {
                            "direction": "bottom",
                            "enable": true,
                            "outModes": {
                                "default": "out"
                            },
                            "size": true,
                            "speed": {
                                "min": 1,
                                "max": 3
                            }
                        },
                        "number": {
                            "value": 750,
                            "density": {
                                "enable": true,
                                "area": 800
                            }
                        },
                        "opacity": {
                            "value": this.isSolved() ? 1 : 0,
                            "animation": {
                                "enable": false,
                                "startValue": "max",
                                "destroy": "min",
                                "speed": 0.3,
                                "sync": true
                            }
                        },
                        "rotate": {
                            "value": {
                                "min": 0,
                                "max": 360
                            },
                            "direction": "random",
                            "move": true,
                            "animation": {
                                "enable": true,
                                "speed": 60
                            }
                        },
                        "tilt": {
                            "direction": "random",
                            "enable": true,
                            "move": true,
                            "value": {
                                "min": 0,
                                "max": 360
                            },
                            "animation": {
                                "enable": true,
                                "speed": 60
                            }
                        },
                        "size": {
                            "value": {
                                "min": 3,
                                "max": 5
                            }
                        },
                        "roll": {
                            "darken": {
                                "enable": true,
                                "value": 30
                            },
                            "enlighten": {
                                "enable": true,
                                "value": 30
                            },
                            "enable": true,
                            "speed": {
                                "min": 15,
                                "max": 25
                            }
                        },
                        "wobble": {
                            "distance": 30,
                            "enable": true,
                            "move": true,
                            "speed": {
                                "min": -15,
                                "max": 15
                            }
                        }
                    }
                }}
            />
            </div>
        </nav>
    }

    tickers() {
        return this.state.assignments.map((i, j) => (i >= 0 ? this.state.companies[i].ticker : `#${j + 1}`))
    }

    render_main() {
        return <main className="col-md-8 ms-sm-auto px-md-4">
            <div className="table-responsive">
                <Table className={'table-hover table-striped-columns table-sm'}>
                    <Assets data={this.state.assets} tickers={this.tickers()}/>
                    <Liabilities data={this.state.liabilities} tickers={this.tickers()}/>
                    <Calculations data={this.state.calculations} tickers={this.tickers()}/>
                </Table>
            </div>
        </main>
    }


    render() {
        return <body>
        <Header url={this.props.url}
                game_id={this.props.game_id}
                allCorrect={ () => this.isSolved()}
                allSelected={ () => this.state.assignments.every((x) => (x >= 0)) }
                setAssignments={ (x: Array<boolean>) => this.setState({
                    correct: this.state.assignments.map((_, i) => x[this.findAssignmentFor(i)])
                }) }
                getAssignments={ () => this.state.assignments }
                getTickers={() => this.state.companies.map(x => x.ticker)}
        />
        <div className="container-fluid">
            <div className="row">
                {this.render_sidebar()}
                {this.render_main()}
            </div>
        </div>
        </body>
    }

    private companyAbout(c: Company) {
        const popover = (
            <Popover id="popover-basic">
                <Popover.Header as="h3">{c.ticker}</Popover.Header>
                <Popover.Body>{c.short_description}</Popover.Body>
            </Popover>
        );

        const CompanyAbout = () => (
            <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                <Button variant="light">About ${c.ticker}</Button>
            </OverlayTrigger>
        );

        return <CompanyAbout />
    }
}
