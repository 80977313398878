import React from "react";
import {
    AssetsToEquityExplainer,
    AssetTurnoverRatioExplainer,
    CurrentRatioExplainer,
    DaysInventoryExplainer,
    DebtToAssetsExplainer,
    DebtToCapitalizationExplainer,
    EbitdaMarginExplainer,
    InterestCoverageRatioExplainer,
    InventoryTurnoverExplainer,
    NetProfitMarginExplainer,
    QuickRatioExplainer, ReceivablesCollectionPeriod,
    ReturnOnAssetsExplainer,
    ReturnOnEquityExplainer
} from "./Explainers";

interface CalculationsProps {
    tickers: string[],
    data: number[][]
}

export class Calculations extends React.Component<CalculationsProps> {
    calculations = [
        // Liquidity Measures
        <CurrentRatioExplainer/>,
        <QuickRatioExplainer/>,

        // Financing and Leverage
        <DebtToAssetsExplainer/>,
        <DebtToCapitalizationExplainer/>,
        <AssetsToEquityExplainer/>,
        <InterestCoverageRatioExplainer/>,

        // Profitability Measures
        <NetProfitMarginExplainer/>,
        <ReturnOnEquityExplainer/>,
        <ReturnOnAssetsExplainer/>,
        <EbitdaMarginExplainer/>,

        // Productivity Measures
        <AssetTurnoverRatioExplainer/>,
        <InventoryTurnoverExplainer/>,
        <DaysInventoryExplainer/>,
        <ReceivablesCollectionPeriod/>,
    ];

    render() {
        return <tbody>
        <tr className={'table-primary'}>
            <th key='ratios-header'>Financial Ratios</th>
            {this.props.tickers.map((x, _) => (<th scope='col'>{x}</th>))}
        </tr>
        {this.calculations.map((title, index) => (
            <tr key={`ratios-${index}`}>
                <td>{title}</td>
                {this.props.data.map((x, i) => (
                    <td key={`ratios-${index}-${i}`}>{(1.0 * x[index]).toFixed(2)}</td>
                ))}
            </tr>
        ))}
        </tbody>
    }
}