import React from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FormRange from "react-bootstrap/FormRange";
import {Card, CardGroup, Form, FormLabel} from "react-bootstrap";
import Row from "react-bootstrap/Row";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


interface Company {
    ticker: string;
    name: string;
}


interface SelectionProps {
    url: string;
}

interface SelectionState {
    requiredCompanies: Company[];
    nCompanies: number;
    maxAge: number;
    minCompanies: number;
    maxCompanies: number;
    diversifyIndustries: boolean;
    minMarketCap: number;
    hardness: string;
    market_cap_percentiles: number[];
    age_percentiles: number[];
    sectors: string[];
    categories: string[];
    companies: Company[];
    sectorsScreened: string[];
}

export default class Selection extends React.Component<SelectionProps, SelectionState> {
    constructor(props: SelectionProps) {
        super(props);
        this.state = {
            nCompanies: 4,
            minCompanies: 2,
            maxCompanies: 12,
            minMarketCap: 50,
            diversifyIndustries: true,
            requiredCompanies: [],
            hardness: "Easy",
            market_cap_percentiles: [],
            age_percentiles: [],
            sectors: [],
            sectorsScreened: [],
            categories: [],
            companies: [],
            maxAge: 50
        }
        fetch(`${this.props.url}/describe`)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    market_cap_percentiles: data["market_cap_percentiles"],
                    age_percentiles: data["age_percentiles"],
                    sectors: data["sectors"],
                    categories: data["categories"],
                    companies: data["tickers"]
                })
            })
            .catch(error => console.error(error))
    }

    getGameUrl() {
        const n = this.state.nCompanies;
        const u = this.state.diversifyIndustries ? "true" : "false";
        const r = encodeURIComponent(JSON.stringify(this.state.requiredCompanies.map(x => x.ticker)));
        const s = encodeURIComponent(JSON.stringify(this.state.sectorsScreened));
        const c = this.state.minMarketCap ? this.state.market_cap_percentiles[this.state.minMarketCap] : -9999999999999;
        const a = this.state.maxAge === this.state.age_percentiles.length - 1 ? 9999999999999 : this.state.age_percentiles[this.state.maxAge];
        return `${this.props.url}/game/new?n=${n}&u=${u}&r=${r}&s=${s}&c=${c}&a=${a}`;
    }

    computeHardness(nCompanies: number, nRequiredCompanies: number, diversifyIndustries: boolean) {
        const score = (nCompanies - 0.5 * nRequiredCompanies) * (diversifyIndustries ? 0.75 : 1);
        if (score <= 3) {
            return "Easy";
        }
        if (score <= 6) {
            return "Medium";
        }
        if (score <= 9) {
            return "Hard";
        }
        return "Very Hard";
    }

    needsRandomCompanies() {
        return this.state.requiredCompanies.length < this.state.nCompanies;
    }

    updateIndustryUniqueness(diversifyIndustries: boolean) {
        this.setState({
            diversifyIndustries: diversifyIndustries,
            hardness: this.computeHardness(this.state.nCompanies, this.state.requiredCompanies.length, diversifyIndustries)
        })
    }

    updateNCompanies(nCompanies: number) {
        this.setState({
            nCompanies: nCompanies,
            hardness: this.computeHardness(nCompanies, this.state.requiredCompanies.length, this.state.diversifyIndustries)
        })
    }

    updateMinMarketCap(minMarketCap: number) {
        this.setState({
            minMarketCap: minMarketCap
        })
    }

    updateAgeCap(maxAge: number) {
        this.setState({
            maxAge: maxAge
        })
    }

    render() {
        return <body>
        <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <a className={`navbar-brand col-md-3 col-lg-2 me-0 px-3 text-primary}`}
               href="/">seagrass</a>
            <Col md={7} lg={8}></Col>
        </header>
        <div className="col-md-2"/>
        <Col className="container-fluid col-md-8">
            <Card>
                <h5 className="card-header">
                    Welcome to seagrass, a game that sharpens your understanding of balance sheets and financial ratios.
                </h5>
                <div className="card-body">
                    <p>Configure your next game below. Click Start. Match companies to their correct balance sheets.
                        Share on Twitter. Repeat.</p>
                    <p>Visit the <a href="https://marcow.io/introducing-seagrass-game-comparing-balance-sheets-financial-statements-matching/">tutorial blog post here</a>.</p>
                    <p>{this.formatGameDescription()}</p>
                    <Button id="submitButton"
                            onClick={() =>
                                fetch(this.getGameUrl())
                                    .then(response => response.json())
                                    .then(data => {
                                        window.location.replace(`/?game_id=${data["game_id"]}`)
                                    })
                                    .catch(error => console.error(error))}>Generate</Button>
                </div>
            </Card>
            <CardGroup>
                <Card>
                    <h5 className="card-header">
                        You can adjust difficulty by selecting below how many companies to include in the game:
                    </h5>
                    <div className="card-body">
                        <h6>Game will include {this.state.nCompanies} companies.</h6>
                        <Row>
                            <FormRange id="n_companies_range" min={this.state.minCompanies}
                                       max={this.state.maxCompanies}
                                       value={this.state.nCompanies}
                                       onChange={(e) => this.updateNCompanies(e.target.valueAsNumber)}
                            />
                        </Row>
                    </div>
                </Card>
            </CardGroup>
            <CardGroup>
                <Card>
                    <h5 className="card-header">
                        Ensure certain companies appear in the game.
                    </h5>
                    <div className="card-body">
                        <Autocomplete
                            id="company-select"
                            multiple
                            options={this.state.companies}
                            autoHighlight
                            getOptionLabel={(option) => `${option.ticker} - ${option.name}`}
                            onChange={(event: React.SyntheticEvent, value: Array<Company>) => this.updateRequiredCompanies(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Include Companies"
                                />
                            )}
                        />
                    </div>
                </Card>
            </CardGroup>
            <CardGroup>
                <Card>
                    <h5 className="card-header">
                        Screen out sectors or require diversity.
                    </h5>
                    <div className="card-body">
                        <Form>
                            <Form.Check
                                type="switch"
                                id="unique-sector-switch"
                                label="Diversify sectors"
                                defaultChecked={this.state.diversifyIndustries}
                                onChange={(e) => this.updateIndustryUniqueness(e.target.checked)}
                                disabled={!this.needsRandomCompanies()}
                            />
                            <hr/>
                            {this.state.sectors.map(x =>
                                <Form.Check
                                    type="switch"
                                    id={`industry-switch-${x}`}
                                    label={x}
                                    defaultChecked={true}
                                    onChange={(e) => this.updateIndustrySelection(x, e.target.checked)}
                                    disabled={!this.needsRandomCompanies()}
                                />
                            )}
                        </Form>
                    </div>
                </Card>
                <Card>
                    <h5 className="card-header">
                        Screen out old/small companies.
                    </h5>
                    <div className="card-body">
                        <Row>
                            <FormLabel htmlFor="market_cap_range">{this.formatMarketCap()} minimum market
                                cap.</FormLabel>
                            <FormRange id="market_cap_range" min={0} max={99}
                                       value={this.state.minMarketCap}
                                       onChange={(e) => this.updateMinMarketCap(e.target.valueAsNumber)}
                                       disabled={!this.needsRandomCompanies()}
                            />
                        </Row>
                        <Row>
                            <FormLabel htmlFor="age_range">{this.formatAge()} max age.</FormLabel>
                            <FormRange id="market_cap_range" min={0} max={99}
                                       value={this.state.maxAge}
                                       onChange={(e) => this.updateAgeCap(e.target.valueAsNumber)}
                                       disabled={!this.needsRandomCompanies()}
                            />
                        </Row>
                    </div>
                </Card>
            </CardGroup>
        </Col>
        <div className="col-md-2"/>
        </body>
    }

    private updateRequiredCompanies(requiredCompanies: Array<Company>) {
        const minCompanies = Math.max(this.state.minCompanies, requiredCompanies.length);
        const maxCompanies = Math.max(this.state.maxCompanies, requiredCompanies.length);
        const nCompanies = Math.min(maxCompanies, Math.max(minCompanies, this.state.nCompanies));
        this.setState({
            requiredCompanies: requiredCompanies,
            minCompanies: minCompanies,
            maxCompanies: maxCompanies,
            nCompanies: nCompanies,
            hardness: this.computeHardness(nCompanies, requiredCompanies.length, this.state.diversifyIndustries)
        })
    }

    private updateIndustrySelection(industry: string, selected: boolean) {
        if (selected) {
            this.setState({
                sectorsScreened: this.state.sectorsScreened.filter(x => x !== industry)
            });
            return;
        }
        const updated = this.state.sectorsScreened;
        updated.push(industry);
        this.setState({
            sectorsScreened: updated
        });
    }

    private formatMarketCap() {
        const actual = this.state.market_cap_percentiles[this.state.minMarketCap];
        if (!actual) {
            return "No";
        }
        if (actual <= 1000000000) {
            return `$${(actual / 1000000).toFixed(0)}M`
        }
        return `$${(actual / 1000000000).toFixed(1)}B`
    }

    private formatAge() {
        const actual = this.state.age_percentiles[this.state.maxAge];
        const years = (actual / 365).toFixed(2);
        const quarters = (actual / 90).toFixed(0);
        return `${years} years (${quarters} quarters)`;
    }

    private formatGameDescription() {
        var description = `Your game will include ${this.state.nCompanies} `;
        if (this.state.requiredCompanies.length) {
            const ticker_list = this.state.requiredCompanies.map(x => `$${x.ticker}`).join(', ');
            description += ` including the following: ${ticker_list}.`
        } else {
            description += 'companies.'
        }
        if (this.state.nCompanies == this.state.requiredCompanies.length) {
            description += ' No random companies will be selected.'
            return description;
        }
        if (this.state.diversifyIndustries) {
            description += " seagrass will prioritize sector diversificaiton when selecting random companies."
        }
        description += ` Any companies with financials older than ${this.formatAge()} days or market capitalizitons
        less than ${this.formatMarketCap()} will not be selected.`
        if (this.state.sectorsScreened.length) {
            const screened = this.state.sectorsScreened.join(", ");
            description += ` Additionally, companies from the following sectors will not be selected: ${screened}.`
        }
        description += ` This game's estimated difficulty score is ${this.state.hardness}.`;
        return description;
    }
}
